<template>
<div
  :id="elementId"
  class="image-drop-container"
  :class="{ over: isOver }"
  @dragenter="onDragEnter"
  @dragleave="onDragLeave"
  @dragover="onDragOver"
  @drop.stop="onDrop">

  <div class="overlay">
    <slot name="file-hover"/>
  </div>

  <slot/>

</div>
</template>
<script>

export default {
  name: 'ImageDropContainer',
  props: {
    maxWidth: {
      type: Number,
    },
    maxHeight: {
      type: Number,
    },
    minWidth: {
      type: Number,
    },
    minHeight: {
      type: Number,
    },
    crop: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOver: false,
      count: 0,
      pointer: 0,
      file: null,
      url: null,
      name: null,
      tags: null,
      elementId: 'image-drop-container1'
    };
  },
  components: {},
  created() {
    this.elementId = "idc" + Math.random().toString(36).substring(2, 15);
  },
  watch: {},
  computed: {},
  methods: {
    addImages(files) {
      //console.log('adding files', files);
      this.count = files.length;
      this.pointer = 0;
      this.$emit('progress', {
        counter: 0,
        percent: 0,
      })
      for (let i = 0; i < files.length; i++) {
        this.loadImage(files[i])
      }
    },
    loadImage(file) {
      var options =  {
        meta: true,
        crop: this.crop,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        minWidth: this.minWidth,
        minHeight: this.minheight,
      }
      return this.$store.dispatch('loadFileImage', { file, options })
        .then( image => {
          this.$emit('added', image);
          this.pointer++;
          this.$emit('progress', {
            counter: this.pointer,
            percent: Math.round(this.pointer/this.count * 100),
          })
        }).finally( () => {
          this.loading = false;
        }).catch( err => {
          this.$emit('error', err);
        })
    },
    onDragEnter() {
      if(!this.disabled ){
        this.isOver = true;
      }
      //console.log('enter', e.toElement)
    },
    onDragLeave(e) {
      if(!this.disabled && e.target.id === this.elementId){
        this.isOver = false;
      }
    },
    onDragOver(e) {
      if(!this.disabled ){
        e.stopPropagation();
        e.preventDefault();
        //this.isOver = true;
      }
      //console.log('over', e)
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.isOver = false;
      if(e.dataTransfer.files.length > 0) {
        this.$emit('dropped', e.dataTransfer.files.length);
        this.addImages(e.dataTransfer.files);
      } else {
        this.$emit('added', e);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.image-drop-container  {
  position: relative;
  min-height: 2rem;
  min-width: 2rem;

  .overlay {
    display: none;
  }

  &.over {
    * {
      pointer-events: none;
    }
    .overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      //border: 0.25rem dashed rgba(0,0,0,0.5);
      border: 0.25rem dashed;
      width: calc(100% - 0.5rem);
      height: calc(100% - 0.5rem);

      background-color: $hover-bkgd;
    }
  }

}
</style>
