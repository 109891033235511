<template>
<ImageDropContainer
  class="image"
  :max-width="300"
  :max-height="300"
  :crop="true"
  @added="processDrop">
  <img
    v-if="url"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    :src="url"/>
  <div
    v-if="!url && name"
    class="placeholder">
    {{ name }}
  </div>
  <div
    class="actions row">
    <slot/>
  </div>
</ImageDropContainer>
</template>
<script>
import ImageDropContainer from '@/components/ImageDropContainer';

export default {
  name: 'Image',
  props: {
    name: {
      type: String,
    },
    url: {
      type: String,
    },
    source: {
      type: String,
      default: 'photopoints',
    },
    action: {
      type: String,
      default: 'transfer',
    },
    index: {
      type: [Number, String],
    },
  },
  data() {
    return {
      file: null,
    //  url: null,
    //  name: null,
      tags: null,
    };
  },
  components: {
    ImageDropContainer,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    onDragStart(e){
      e.dataTransfer.setData('action', this.action)
      e.dataTransfer.setData('source', this.source)
      e.dataTransfer.setData('index', this.index)
    },
    onDragEnd(e){
      e.dataTransfer.clearData();
    },
    processDrop(e) {
      if(e.target) {
        this.processEvent(e);
      } else {
        this.processImage(e);
      }
    },
    processImage(image) {
      // pass it through and
      this.$emit('dropped', image)
    },
    processEvent(e) {
      e.stopPropagation();
      e.preventDefault();
      var action = e.dataTransfer.getData('action');
      var source = e.dataTransfer.getData('source');
      var index = e.dataTransfer.getData('index');
      this.$emit('dropped', { action, source, index })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.image  {

  height: 8rem;
  width: 8rem;

  &.large {
    height: 20rem;
    width: 20rem;
  }

  &.small {
    height: 15rem;
    width: 15rem;
  }

  &.tiny {
    height: 10rem;
    width: 10rem;
  }

  margin: 0.5rem;
  //border: 0.25rem dashed rgba(0,0,0,0.5);
  border: 0.25rem dashed;
  &:hover {
    background-color: $hover-bkgd;
  }
  img {
    width: 100%;
  }

  .actions {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

}
</style>
