<template>
<div class="subscription-section">

  <div class="padded">

  </div>

  <div class="pricing-section">

    <PricingCard
      class="card"
      category="free"
      :price="0"
      :features="basicFeatures"
      @selected="handleSelected('free')"/>

    <PricingCard
      class="card"
      category="premium"
      :price="10"
      :features="premiumFeatures"
      action-label="subscribe"
      @selected="handleSelected('premium')"/>

    <PricingCard
      class="card"
      category="Ouzel"
      :price="2500"
      period="year"
      :features="ouzelFeatures"
      action-label="Learn more"
      @selected="handleSelected('ouzel')"/>

  </div>

  <StripePaymentForm
    v-if="stripeSessionId"
    :session-id="stripeSessionId"
    :api-key="stripeKey"
    />

  <!-- <Features -->
  <!--   :hero="true" -->
  <!--   img="https://picsum.photos/id/252/500"> -->
    <!--   <h2> -->
    <!--     Interested in Ouzel -->
    <!--   </h2> -->
    <!--   <p> -->
    <!--     Nulla felis felis, semper non dui quis, ultrices tempus lectus. Mauris fermentum porttitor luctus. Aenean ac ultrices ex, sit amet blandit ex. Etiam condimentum lorem vitae mauris imperdiet, a viverra diam lobortis. Sed tempor mauris vel tempor tincidunt. Phasellus dictum luctus augue. Donec dapibus lacus ut arcu posuere, vitae molestie neque convallis. -->
    <!--   </p> -->
    <!-- </Features> -->

    <!-- <Features -->
    <!--   :hero="true" -->
    <!--   img="https://picsum.photos/id/252/500"> -->
    <!--   <h2> -->
    <!--     Interested in Ouzel -->
    <!--   </h2> -->
    <!--   <p> -->
    <!--     Nulla felis felis, semper non dui quis, ultrices tempus lectus. Mauris fermentum porttitor luctus. Aenean ac ultrices ex, sit amet blandit ex. Etiam condimentum lorem vitae mauris imperdiet, a viverra diam lobortis. Sed tempor mauris vel tempor tincidunt. Phasellus dictum luctus augue. Donec dapibus lacus ut arcu posuere, vitae molestie neque convallis. -->
    <!--   </p> -->
    <!-- </Features> -->


</div>
</template>
<script>
import PricingCard from '@/components/PricingCard';
//import Features from '@/components/Features';
import StripePaymentForm from '@/components/StripePaymentForm';
import { post } from '@/api'

export default {
  name: 'SubscriptionSection',
  props: {
    preventedAction: {
      type: String,
    }
  },
  data() {
    return {
      stripeKey: null,
      stripeSessionId: null,
      basicFeatures: [
        'Completely client side',
        'Automatically matches images based on location',
        'Option to build report from image locations',
        'Unlimited Photo Point Locations',
        'Stores one property',
      ],
      premiumFeatures: [
        'All the basic features',
        'Store and switch between multiple properties',
      ],
      ouzelFeatures: [
        'Store and switch between multiple properties',
        'Save all your images online',
        'Compare images across multiple years',
        'Combine photo points with other monitoring features'
      ],
    };
  },
  components: {
    //Features,
    PricingCard,
    StripePaymentForm,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    handleSelected(x) {
      if(x === 'free') {
        post('ppr/unsubscribe')
          .then( () => {
            this.$store.dispatch('user/validate');
            this.$store.dispatch('loadPropertyList');
            this.$emit('close');
          })
      } else if(x === 'ouzel') {
        window.open('https://www.ouzel.land', '_blank');
      } else if(x === 'premium') {
        // post('stripe/checkoutSession')
        //   .then( res => {
        //     this.stripeKey = res.key;
        //     this.stripeSessionId = res.id;
        //     console.log(res)
        //   })
        post('ppr/subscribe')
          .then( () => {
            this.$store.dispatch('user/validate')
            this.$store.dispatch('loadPropertyList');
            this.$emit('close');
          })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.subscription-section  {
  overflow: auto;

  .pricing-section {
    display: flex;
    flex-direction: row;
    padding: 2rem;

    .card {
      @include shadow;
      margin: 1rem;
      width: calc(33% - 2rem);
    }
  }
}
</style>
