<template>
<div
  class="editable-markdown-div"
  @click.stop="">

  <div
    v-show="editing"
    class="edit-container">
    <textarea
      ref="textarea"
      :placeholder="placeholder"
      v-model="internalText"/>
    <div class="actions row">
      <IconLink
        class="cancel-button"
        @clicked="editing = false">
        close
      </IconLink>
      <IconLink
        class="save-button"
        v-show="internalText != text"
        @clicked="save">
        save
      </IconLink>
    </div>

  </div>

  <div
    class="display-container"
    v-show="!editing"
    v-html="compiledMarkdown"
    @click="editText"/>

</div>
</template>
<script>
import Marked from 'marked';
import IconLink from '@acorns-ui/Icons/IconLink'

export default {
  name: 'EditableMarkdownDiv',
  props: {
    text: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Add your notes here',
    },
    breaks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editing: false,
      internalText: null,
    };
  },
  components: {
    IconLink,
  },
  created() {
    this.internalText = this.text;
  },
  watch: {
    text(a) {
      this.internalText = a;
    }
  },
  computed: {
    compiledMarkdown() {
      var txt = this.internalText || this.placeholder;
      return Marked(txt, {
        breaks: this.breaks,
      });
    },
  },
  methods: {
    editText() {
      if(!this.$attrs['onClick']) {
        this.editing = true;
      }
    },
    save() {
      this.$emit('updated', this.internalText);
      this.editing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
$background: #d6e9f3;

.editable-markdown-div  {

  .edit-container {
    position: relative;
    //padding: 0.5rem;
    height: calc(100% - 1rem);
    width: calc(100% - 1rem);

    textarea {
      height: 100%;
      width: 100%;
      resize: none;
      padding: 0.5rem;
    }

    .actions {
      position: absolute;
      bottom: 0;
      right: 0;
      .save-button {
        background-color: $green;
      }
      .cancel-button {
        background-color: $yellow;
      }
    }
  }

  .display-container {

    p:first-child {
      color: red;
    }

    img {
      max-width: 100%;
    }
    video {
      max-width: 100%;
    }
    pre {
      code {

        background:#1d1f21;
        display:block;
        margin: 1rem;
        padding:1em;
        overflow-x:auto;
        background:#1d1f21;
        color:#c5c8c6;
        // max-width: $break-xs;

        .hljs-comment, .hljs-quote {
          color:#969896;
        }
        .hljs-variable, .hljs-template-variable, .hljs-tag, .hljs-name, .hljs-selector-id, .hljs-selector-class, .hljs-regexp, .hljs-deletion {
          color:#cc6666;
        }
        .hljs-number, .hljs-built_in, .hljs-builtin-name, .hljs-literal, .hljs-type, .hljs-params, .hljs-meta, .hljs-link {
          color:#de935f;
        }
        .hljs-attribute {
          color:#f0c674;
        }
        .hljs-string, .hljs-symbol, .hljs-bullet, .hljs-addition {
          color:#b5bd68;
        }
        .hljs-title, .hljs-section {
          color:#81a2be;
        }
        .hljs-keyword, .hljs-selector-tag {
          color:#b294bb;
        }

        .hljs-emphasis {
          font-style:italic;
        }
        .hljs-strong {
          font-weight:bold;
        }
      }
    }

    &.clickable {
      cursor: pointer;
    }

    blockquote {
      background-color: $background;
      padding: 1rem;
      border-left: 1rem solid darken($background, 10%);
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
  }

  a {
    word-break: break-all;
  }

  li {
    margin-top: 0.5rem;
  }
  }
}
</style>
