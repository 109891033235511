<template>
<div
  class="image-viewer"
  @click.stop="">
  <div class="header">
    <div class="info">
      {{ label }}
    </div>
    <div class="actions">
      <IconLink
        @clicked="$emit('close')">
        close
      </IconLink>
    </div>
  </div>
  <div class="content">
    <div class="img-container">
      <img
        v-if="!loading && image && image.url"
        :src="image.url"/>
      <img
        v-else-if="!loading"
        :src="url"/>
    </div>
    <div class="image-details">
      <div class="tab-buttons">
        <a
          class="tab-button"
          :class="{ active: tab == 'summary'}"
          @click="tab = 'summary'">
          Summary
        </a>
        <a
          class="tab-button"
          :class="{ active: tab == 'gps'}"
          @click="tab = 'gps'">
          GPS
        </a>
        <a
          class="tab-button"
          :class="{ active: tab == 'exif'}"
          @click="tab = 'exif'">
          Exif
        </a>
      </div>

      <div class="tabs">
        <div
          v-if="tab == 'summary'"
          class="tab">
          <InfoLine
            title="Date/time">
            {{ datetime }}
          </InfoLine>

          <InfoLine
            title="Latitude">
            {{ lat }}
          </InfoLine>
          <InfoLine
            title="Longitude">
            {{ lng }}
          </InfoLine>
          <InfoLine
            title="Altitude">
            {{ alt || 'Not available'}}
          </InfoLine>
          <InfoLine
            title="Direction">
            {{ direction || 'Not available'}}
          </InfoLine>

          <InfoLine
            :fill="true"
            :inline="false"
            title="Notes">
            <EditableMarkdownDiv
              class="fill"
              :text="notes"
              @updated="$emit('updated', { key: 'notes', value: $event })"/>
          </InfoLine>
        </div>

        <div
          v-if="tab == 'gps'"
          class="tab">
          <InfoLine
            v-for="g in gps"
            :key="g.key"
            :title="g.key">
            {{ g.value }}
          </InfoLine>
        </div>

        <div
          v-if="tab == 'exif'"
          class="tab">
          <InfoLine
            v-for="e in exif"
          :key="e.key"
          :title="e.key">
          {{ e.value }}
        </InfoLine>
      </div>
      </div>

    </div>
  </div>
  <div class="footer">
    <div class="info row">
      <IconLink
        @clicked="rotateImage(-1)">
        undo
      </IconLink>
      <IconLink
        @clicked="rotateImage(1)">
        redo
      </IconLink>

    </div>
    <div class="actions row">
      <IconLink
        @clicked="$emit('previous')">
        chevron_left
      </IconLink>
      <IconLink
        @clicked="$emit('next')">
        chevron_right
      </IconLink>
    </div>
  </div>

</div>
</template>
<script>
import IconLink from '@acorns-ui/Icons/IconLink';
import InfoLine from '@acorns-ui/Text/InfoLine';
import EditableMarkdownDiv from '@/components/EditableMarkdownDiv';
import { get } from '@/utils';

export default {
  name: 'ImageViewer',
  props: {
    label: {
      type: String,
    },
    url: {
      type: String,
    },
    file: {
      type: File,
    },
    name: {
      type: String,
    },
    notes: {
      type: String,
    },
    datetime: {
      type: String,
    },
  },
  data() {
    return {
      tab: 'summary',
      editNotes: false,
      image: null,
      loading: false,
      rotation: 0,
      options:  {
        maxWidth: 1184,
        maxHeight: 804,
      }
    };
  },
  components: {
    IconLink,
    InfoLine,
    EditableMarkdownDiv,
  },
  created() {
  },
  mounted() {
    const img = document.querySelector('.img-container');
    if(img) {
      this.options.maxWidth = img.clientWidth;
      this.options.maxHeight = img.clientHeight;
    }
    if(this.file) {
      this.loadFileImage(this.file, this.options);
    }
  },
  watch: {
    name(a, b) {
      console.log('watching name', a, b);
      this.loadFileImage(this.file, this.options);
    }
  },
  computed: {
    lat() {
      return get(['lat'], this.image);
    },
    lng() {
      return get(['lng'], this.image);
    },
    alt() {
      return get(['alt'], this.image);
    },
    direction() {
      return get(['direction'], this.image);
    },
    orientation() {
      return get(['orientation'], this.image);
    },
    gps() {
      const gps = get(['tags','GPSInfo'], this.image);
      if(gps) {
        return Object.entries(gps).map(([key, value]) => ({key,value}));
      } else {
        return [];
      }
    },
    exif() {
      const exif = get(['tags','Exif'], this.image);
      if(exif) {
        return Object.entries(exif).map(([key, value]) => ({key,value}));
      } else {
        return [];
      }
    }
  },
  methods: {
    rotateImage(step = 1) {
      const file = this.file;
      var orientation = 1;
      if((this.rotation + step) >= 4) {
        this.rotation = 0;
      } else if((this.rotation + step) < 0) {
        this.rotation = 3;
      } else {
        this.rotation = this.rotation + step;
      }

      if(this.orientation == 1) {
        orientation = [1,6,3,8][this.rotation];
      } else if(this.orientation == 6){
        orientation = [6,3,8,1][this.rotation];
      }

      this.loadFileImage(file, { ...this.options, orientation });
    },
    loadFileImage(file, options = {}) {
      this.loading = true;
      console.log('load file image', options);
      this.$store.dispatch('loadFileImage', { file, options })
        .then( image => {
          this.image = image;
        }).finally( () => {
          this.loading = false;
        }).catch( err => {
          console.log('error loading file', err);
        })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.image-viewer  {
  width: 95vw;
  height: 95vh;

  display: flex;
  flex-direction: column;

  .header {
    @include row;
    height: 4rem;
    margin-bottom: 0;
    .info {
      flex: 1;
      font-size: 1.5rem;
      padding-left: 1rem;
    }
    .actions {
      padding-right: 1rem;
    }
  }

  .content {
    //flex: 1 1 auto;
    display: flex;
    height: calc(100% - 7rem);

    .img-container {
      display: flex;
      //justify-content: center;
      flex: 1;
      border-right: $border-style;
      background-color: #3d424e;
      img {
        //width: 100%;
      }
    }

    .image-details {
      flex: 0 0 30rem;
      display: flex;
      flex-direction: column;
      .tab-buttons {
        height: 2rem;
      }

      .tabs {
        height: calc(100% - 2rem);
        .tab {
          height: 100%;
          overflow: auto;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .footer {
    //@include footer;
    @include row;
    height: 3rem;
    padding: 0 1rem;
    .info {
      flex: 1;
    }
  }
}
</style>
