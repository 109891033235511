<template>
<div
  class="photo-points-upload"
  :class="{ startup }">

  <div
    v-if="!startup"
    class="tray-container sidebar-width">
    <ImageDropContainer
      class="tray-image-drop-container"
      :max-height="300"
      :max-width="300"
      :crop="true"
      @added="addToTray"
      @dropped="startProgressBar"
      @progress="updateProgressBar">
      <div class="tray-header">
        Photo Tray
      </div>
      <div
        class="instructions"
        v-if="!tray.length">
        <div
          class="padded italics">
          Drop files to start organizing, photos with location data will be automatically added to the photo point locations.
        </div>
      </div>
      <div
        class="image-tray">
        <div
          class="image-thumbnail"
          v-for="(img, i) in tray"
          :key="img.name"
          :draggable="true"
          @dragstart="onDragStart($event, i, 'tray')"
          @dragend="onDragEnd">
          <div
            class="image-container"
            >
            <img
              :draggable="false"
              :src="img.url"
              >
          </div>
          <div class="image-details">
            <div class="image-name">
              {{ img.name }}
            </div>
            <div class="image-date">
              {{ img.datetime }}
            </div>
            <!-- <div class="image-size"> -->
            <!--   {{ img.size }} -->
            <!-- </div> -->
            <IconLink
              :success="true"
              v-if="img.notes">
              check
            </IconLink>
            <div
              v-if="img.closest"
              class="image-match">
              Nearest: {{ img.closest.label }}
            </div>
            <!-- get tray image and then do nothing with it -->
            <!-- this way its removed from local storage as well -->
            <IconLink
              v-if="tray.length"
              class="remove-tray-link"
              @clicked="$store.dispatch('getTrayImage', { idx: i })">
              close
            </IconLink>
          </div>
        </div>
      </div>
      <div class="tray-footer">
        <IconLink
          v-if="tray.length"
          @clicked="$store.dispatch('updateField', { key: 'tray', value: [] })">
          close
        </IconLink>
        <Tag
          v-if="!hasPhotoPoints"
          title="Add a new photo point location"
          icon="add"
          theme="small"
          @clicked="addPhotoPointLocation()">
          Add location
        </Tag>
      </div>
    </ImageDropContainer>
  </div>

  <div class="main-container">

    <div
      v-if="startup"
      class="startup-instructions">
      <div class="padded italics smaller">
        Drop images on the map, drop a pin or select a property from the list below. Use the search box to find a specific location on the map.
      </div>

      <div class="row padded">
        <input
          class="fill"
          v-model="geocoderSearchString"/>
        <IconLink
          @clicked="geocoder">
          search
        </IconLink>
        <IconLink
          v-if="geocoderSearchString"
          @clicked="geocoderSearchString=null;geocoderSearchResults=[];">
          close
        </IconLink>
      </div>

      <div class="geocoder-list-container">
        <div
          v-if="geocoderSearchString && geocoderSearched && !geocoderSearchResults.length"
          class="padded italics">
          No results
        </div>
        <div
          class="clickable padded row center"
          v-for="p in geocoderSearchResults"
          :key="p.place_id">
          <a
            class="fill"
            @click="updateMapLocation(p)">
            {{ p.formatted_address }}
          </a>
          <IconLink
            class="small"
            title="Add a new photo point at this location"
            @clicked="addPhotoPointLocation(p.geometry.location)">
            place
          </IconLink>
        </div>
      </div>

      <div
        v-if="premium && !(geocoderSearchString && (geocoderSearching || geocoderSearched))"
        class="list-container">
        <a
          v-for="p in filteredPropertiesList"
          :key="p.property_id"
          class="property-item"
          @click="selectProperty(p.uid)">
          {{ p.label }}
        </a>
      </div>
    </div>

    <!-- This needs to be `before` the photo points so that the -->
    <!-- modal comes out on top -->
    <ImageDropContainer
      class="map-container details-width"
      :max-height="300"
      :max-width="300"
      :crop="true"
      @added="addToTray"
      @dropped="startProgressBar"
      @progress="updateProgressBar">
      <div
        class="stick">
        <LeafletMap
          ref="map"
          :key="mapVersion"
          :center="currentMapCenter"
          :viewport="geocoderSearchViewport"
          :zoom="mapZoom"
          :layers="mapLayers"
          :points="data"
          :editable="true"
          @click="mapClickHandler"
          @map-moved="mapMoveHandler"
          @feature-moved="updatePhotoPointLocation"/>
      </div>
    </ImageDropContainer>


    <div class="photo-points-container content-width">

      <div
        v-if="true"
        class="header">

        <!-- <ImageDropContainer -->
        <!--   v-if="!hasPhotoPoints && !loadingProperty" -->
        <!--   class="starter-box" -->
        <!--   :max-height="300" -->
        <!--   :max-width="300" -->
        <!--   :crop="true" -->
        <!--   @added="addToTray" -->
        <!--   @dropped="startProgressBar" -->
        <!--   @progress="updateProgressBar"> -->
        <!--   <div class="default"> -->
        <!--     Click or drop images here to start adding photo points -->
        <!--   </div> -->
        <!--   <div -->
        <!--     class="on-hover" -->
        <!--     @click="addPhotoPointLocation()"> -->
        <!--     Add new photo point location -->
        <!--   </div> -->
        <!--   <template v-slot:file-hover> -->
        <!--     <div class="on-file-hover"> -->
        <!--       Drop your file here -->
        <!--     </div> -->
        <!--   </template> -->
        <!-- </ImageDropContainer> -->


        <div
          class="property-header">

          <div class="fill row center">

            <!-- <IconLink -->
            <!--   @clicked="$store.dispatch('photopoints/clearPhotoPoints')"> -->
            <!--   close -->
            <!-- </IconLink> -->

            <div class="property-label fill">
              <a
                class="clickable italics"
                @click="editForm('propertyName', propertyName, 'updateField')">
                {{ propertyName || 'Enter a property name'}}
              </a>
            </div>
          </div>

          <div class="property-actions row">

            <Tag
              v-if="hasPhotoPoints"
              :icon="minimize ? 'unfold_more' : 'unfold_less'"
              theme="small orange"
              title="Hide the photo point directions"
              @clicked="minimize=!minimize">
              {{ minimize ? 'Show photo points' : 'Hide photo points' }}
            </Tag>

            <!-- <IconLink -->
            <!--   @clicked="$store.dispatch('downloadPhotoPoints')"> -->
            <!--   cloud_download -->
            <!-- </IconLink> -->

            <Tag
              icon="add"
              theme="small success"
              title="Add a new photo point location in the center of the map"
              @clicked="addPhotoPointLocation()">
              Add location
            </Tag>

            <!-- <Tag -->
            <!--   v-if="data.length" -->
            <!--   icon="close" -->
            <!--   theme="small alert" -->
            <!--   title="Clear the current property and start on a new on" -->
            <!--   @clicked="clearProperty"> -->
            <!--   Clear property data -->
            <!-- </Tag> -->

            <Tag
              v-if="filteredPropertiesList.length>0"
              icon="park"
              theme="small orange"
              title="Clear the current property and select a new one from your list"
              @clicked="handlePropertyList">
              {{ !hasPhotoPoints ? 'Load property' : 'Change property' }}
            </Tag>

            <Tag
              v-if="propertyNeedsSaving"
              icon="save"
              theme="small green"
              title="Save the current property data"
              @clicked="handleSaveProperty">
              save property
            </Tag>

            <Tag
              icon="money"
              theme="small attention"
              title="Show the premium modal"
              @clicked="showPremiumModal = true">
              show premium
            </Tag>


            <!-- <IconLink -->
            <!--   @clicked="showHelp=!showHelp"> -->
            <!--   help -->
            <!-- </IconLink> -->


          </div>

        </div>

      </div>

      <div class="grid-container">
        <div
          class="location-container"
          :class="{ minimize }"
          v-for="pl in data"
          :key="pl.uid"
          draggable="false">
          <PhotoPointLocation
            class="location-section"
            :minimize="minimize"
            :id="`pp${pl.uid}`"
            :uid="pl.uid"
            :label="pl.label"
            :description="pl.description"
            :lat="pl.lat"
            :lng="pl.lng"
            :points="pl.photopoints"
            @edit="editableField = $event"
            @remove="removePhotoPointLocation"
            @copy="addPhotoPointLocation"
            @transfer="transferPhotoPointImagesToTray"
            />
        </div>

      </div>

    </div>

  </div>


  <div
    v-if="editableField"
    class="modal-container">
    <EditForm
      :value="editableField.value"
      :type="editableField.type"
      @update="updateField"
      @cancel="editableField = null"/>
  </div>



  <div
    v-if="showPropertyList"
    class="modal-container">
    <div class="list-container">
      <div class="row padded">
        <div class="fill padded">
          Select property from list
        </div>
        <IconLink
          @clicked="showPropertyList = false">
          close
        </IconLink>
      </div>
      <a
        v-for="p in filteredPropertiesList"
        :key="p.uid"
        class="property-item"
        @click="selectProperty(p.uid)">
        {{ p.label }}
      </a>
    </div>
  </div>

  <div
    v-if="showPremiumModal"
    class="modal-container">
    <div
    id="premium-container">
      <div class="row padded">
        <div class="fill padded">

        </div>
        <IconLink
          @clicked="showPremiumModal = false">
          close
        </IconLink>
      </div>

      <SubscriptionSection
        @close="subscriptionCloseHandler"/>

    </div>
  </div>

  <div
    v-if="showHelp"
    class="modal-container">
    <div
      id="help-container">
      <div class="row padded">
        <div class="fill padded">
          Photo Point Reports Hep
        </div>
        <IconLink
          @clicked="showHelp = false">
          close
        </IconLink>
      </div>
      <div class="help-content">
        <div
          class="help-section"
          v-for="h in helpContent"
          :key="h.label">
          {{ h.label }}
          {{ h.content }}
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import LeafletMap from '@/components/LeafletMap';
import PhotoPointLocation from '@/components/PhotoPointLocation';
import ImageDropContainer from '@/components/ImageDropContainer';
import SubscriptionSection from '@/components/SubscriptionSection';
import EditForm from '@/components/EditForm';
//import Button from '@acorns-ui/Inputs/Button';
import IconLink from '@acorns-ui/Icons/IconLink';
import Tag from '@acorns-ui/Text/Tag';
/* global google */

export default {
  name: 'photo-points-upload',
  props: {},
  data() {
    return {
      geocoderSearchString: null,
      geocoderSearched: false,
      geocoderSearching: false,
      geocoderSearchViewport: null,
      geocoderSearchResults: [],
      mapVersion: 1,
      editableField: null,
      mapCenter: {},
      mapZoom: 14,
      showPropertyList: false,
      showPremiumModal: false,
      showHelp: false,
      addingImages: false,
      messageId: null,
      minimize: false,
    };
  },
  components: {
    PhotoPointLocation,
    ImageDropContainer,
    LeafletMap,
    EditForm,
    SubscriptionSection,
    IconLink,
    Tag,
  },
  setup() {
    console.log('setting up', this);
  },
  created() {

  },
  errorCaptured(err, vm, info) {
    console.log(err, vm, info);
    return false;
  },
  watch: {},
  computed: {
    ...mapState([
      'tray',
      'additionalPhotos',
      'progressBarCount',
      'helpContent',
      'prefix',
      'propertyName',
      'propertyId',
      'propertiesList',
      'loadingProperty',
    ]),
    ...mapState('photopoints', [
      'data',
    ]),
    ...mapGetters('photopoints', [
      'center',
      'photoPointLocationIds',
      'photoPointIds',
      // 'nextPhotoPointId',
    ]),
    ...mapGetters([
      'cameraPointsGeojson',
      'imagesGeojson',
      'premium',
      'startup',
      'propertyNeedsSaving',
    ]),
    hasPhotoPoints() {
      return this.data.length>0
    },
    hasTrayImages() {
      return this.tray.length>0
    },
    hasProperties() {
      return this.propertiesList.length>0
    },
    filteredPropertiesList() {
      return this.propertiesList.filter(d=>d.uid!=this.propertyId);
    },
    currentMapCenter() {
      if(this.mapCenter && this.mapCenter.lat) {
        return [ this.mapCenter.lat, this.mapCenter.lng];
      } else {
        return this.center;
      }
    },
    mapLayers() {
      if(this.hasPhotoPoints) {
        return [this.imagesGeojson, this.cameraPointsGeojson];
      } else {
        return []
      }
    },
  },
  methods: {
    updateMapLocation(p) {
      const vp = p.geometry.viewport;
      window.vp = vp

      this.mapZoom = 18;
      this.geocoderSearchViewport = [
        vp.getCenter().lat(),
        vp.getCenter().lng()
      ]
      //this.$refs.map.setView(p.geometry.viewport);
    },
    geocoder() {
      const geocoder = new google.maps.Geocoder();
      const address = this.geocoderSearchString;
      this.geocoderSearching = true;
      this.geocoderSearched = false;
      this.geocoderSearchResults = [];
      return geocoder.geocode({ address }, (results, status) => {
        console.log(results, status)
        if (status === google.maps.GeocoderStatus.OK) {
          this.geocoderSearchResults = results;
        }
        this.geocoderSearching = false;
        this.geocoderSearched = true;
      });
    },
    selectProperty(uid) {
      this.mapCenter={};
      this.mapZoom=14;
      this.dispatchPhotoPoints('loadProperty', uid);
      this.showPropertyList = false;
    },
    refreshMap() {
      if(!this.addingImages) {
        if(this.$refs.map) {
          console.log('refreshing map by function', this.center)
          this.$refs.map.refreshLayers();
          this.$refs.map.setView(this.center);
        } else {
          console.log('refreshing map by key', this.mapVersion, this.$refs)
          this.mapVersion++;
        }
      }
    },
    refreshMapLayer(layer) {
      this.$refs.map.refreshLayerData(layer);
    },
    handleSaveProperty() {
      if(!this.premium) {
        this.showPremiumModal = true;
      } else {
        this.dispatchPhotoPoints('saveProperty');
      }
    },
    handlePropertyList() {
      if(!this.premium) {
        this.showPremiumModal = true;
      } else {
        this.showPropertyList = true
      }
    },
    clearProperty() {
      return this.$store.dispatch('clearProperty')
        .then( () => {
          this.refreshMap();
          this.$store.dispatch('updateField', {
            key: 'pdfsrc',
            value: null,
          });
        });
    },
    dispatchPhotoPoints(action, args) {
      return this.$store.dispatch(action, args)
        .then( () => {
          this.refreshMap();
        });
    },
    editForm(key, value, source, type = 'text') {
      this.editableField = { key, value, source, type}
    },
    createPhotoPointLocationsFromTray() {
      // for each image in the tray we create a location
      for(var i = this.tray.length - 1; i>=0; i--) {
        // if it matches one already we skip
        let image = this.tray[i];
        this.$store.dispatch('addTo', { image })
          .then( () => {
            this.$store.commit('REMOVE_FROM_TRAY', i);
          });
      }
    },
    addPhotoPointLocation(data={}) {
      //const data = { };
      if(!data.lat && this.mapCenter.lat) {
        data.lat = this.mapCenter.lat;
        data.lng = this.mapCenter.lng;
      } else if(typeof(data.lng) === 'function'){
        data.lat = data.lat();
        data.lng = data.lng();
        console.log(data)
      }
      this.$store.dispatch('photopoints/addPhotoPointLocation', data)
        .then( () => {
          if(this.photoPointIds.length == 1) {
            this.mapCenter = {};
            this.refreshMap()
          } else {
            this.refreshMap()
            //this.$refs.map.refreshLayers();
            //this.refreshMapLayer('photopoints')
          }
        });
    },
    transferPhotoPointImagesToTray(id){
      const ppl = this.data.find(d => d.uid === id);
      if(ppl) {
        return Promise.all(
          ppl.photopoints
            .filter(d=>d.image)
            .map(pp => {
              let from = { source: 'photopoints', index: pp.uid };
              let to = { source: 'tray' }
              this.$store.dispatch('transferImage', { from, to });
            })
        );
      } else {
        return new Promise( resolve => resolve(true));
      }
    },
    removePhotoPointLocation2(id) {
      this.transferPhotoPointImagesToTray(id)
        .then( () => {
          this.$store.commit('photopoints/REMOVE_PHOTOPOINT_LOCATION', id);
          this.refreshMap();
        })
    },
    removePhotoPointLocation(id) {
      this.$store.dispatch('photopoints/removePhotoPointLocation', id)
        .then(() => {
          this.refreshMap();
        })
    },
    updatePhotoPointLocation({ feature, layer, latlng }) {
      if(layer == 'photopoints') {
        //let lng = feature.geometry.coordinates[0];
        //let lat = feature.geometry.coordinates[1];
        let uid = feature.properties.uid;
        var { lat, lng } = latlng;
        //var uid = marker.uid;
        console.log('location moved', feature, uid, lat, lng);
        this.$store.dispatch('photopoints/updateCollectionRecordField', { uid, lat, lng });
      }
    },
    updateField(value) {
      const { key, id, source } = this.editableField;
      //this.$store.commit(source, { uid: id, [key]: value });
      console.log(key, id, source, value);
      if(id) {
        this.$store.dispatch(source, { uid: id, [key]: value });
      } else {
        this.$store.dispatch(source, { key, value });
      }
      this.editableField = null;
      this.refreshMap();
    },
    mapMoveHandler(e) {
      this.mapCenter = e.target.getCenter();
      this.mapZoom = e.target.getZoom()
    },
    onDragStart(e, idx, source = 'tray'){
      e.dataTransfer.setData('action', 'transfer')
      e.dataTransfer.setData('source', source)
      e.dataTransfer.setData('index', idx)
    },
    onDragEnd(e){
      e.dataTransfer.clearData();
    },
    startProgressBar() {
      // var message = 'Loading images';
      // this.$store.dispatch('startProgressBar', { message, limit });
      this.addingImages = true;
      this.$store.dispatch('PageMessages/submitMessage', {
        type: 'progress-message',
        params: {
          label: 'Gathering all your photos',
          progress: 0,
        },
        active: true,
      }).then( id => {
        this.messageId = id;
      })
      //this.showProgressBar = true;
      //this.progress = 0;
    },
    updateProgressBar({ percent }) {
      // const key = 'progressBarCounter';
      //console.log('File upload progress', counter, percent);
      //this.$store.commit('UPDATE_FIELD', { key, value: counter });
      //this.progress = percent;
      // if(percent >= 100) {
      //   this.$store.dispatch('clearProgressBar');
      //   this.showProgressBar = false;
      //   this.progress = 0;
      // }
      console.log('update progress bar', percent);
      this.$store.commit('PageMessages/UPDATE_MESSAGE', {
        id: this.messageId,
        params: {
          label: 'Processing your photos',
          progress: percent,
        }
      });

      if(percent>=100) {
        this.$store.dispatch('PageMessages/deactivateMessage', {
          id: this.messageId,
          timer: 1000,
        });
        this.addingImages = false;
        this.refreshMap();
      }
    },
    addToTray(e) {
      if(e.target) {
        this.addFromEvent(e);
      } else {
        this.addFromImage(e);
      }
    },
    addToAdditionalPhotos(e) {
      if(e.target) {
        this.addFromEvent(e, 'addToAdditionalPhotos');
      } else {
        this.addFromImage(e, 'addToAdditionalPhotos');
      }
    },
    addFromImage(image, method = 'addToTray') {
      if(method == 'addToTray') {
        this.$store.dispatch('addTo', { image })
          .then( () => {
            this.refreshMap();
          })
      } else {
        this.$store.commit(method, image);
      }
    },
    addFromEvent(e, method = 'addToTray') {
      e.stopPropagation();
      e.preventDefault();
      var source = e.dataTransfer.getData('source');
      var index = e.dataTransfer.getData('index');
      // now we need to get the image
      this.$store.dispatch('getImage', { source, index })
        .then( image => {
          //console.log(image, source, index)
          this.$store.dispatch(method, image);
        });
    },
    subscriptionCloseHandler(e) {
      console.log('sub close handler', e);
      this.showPremiumModal = false;
    },
    mapClickHandler(e) {
      if(e.feature && e.feature.properties) {
        this.scrollTo(`pp${e.feature.properties.uid}`);
      } else if(e.lat){
        // add a point using this location
        this.addPhotoPointLocation(e);
      }
    },
    scrollTo(hash){
      const el = document.getElementById(hash);
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/modals';
@import '~@acorns-ui/Styles/screens';

.photo-points-upload  {
  //width: 100%;
  //height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: $white;

  .modal-container {
    @include modal;
  }

  .main-container {
    //position: relative;
    overflow-y: auto;
    //margin-left: 1rem;
    display: flex;
    flex: 1;

    .properties-header {
      padding: 0.75rem;
      border: $border-style;
    }


    .photo-points-container {
      //flex: 0 0 $content-width;

      .grid-container {
       // flex: 0 0 $content-width;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .location-container {
        margin: 0 0 0.5rem 0;
        width: calc(100% - 0.5rem);
        @include shadow;
        background-color: white;
        padding: 0.5rem;

        &.minimize {
          width: 3*$inch;
          margin: 0 0.5rem 0.5rem 0;
        }
      }

      .additional-photos {
        min-height: 20rem;
        img {
          width: 10rem;
        }
      }
    }

    .map-container {
      @include shadow;
      position: absolute;
      right: 1rem;
      top: 1rem;
      //width: $details-width;
      padding: 0rem;
      height: calc(100% - 2rem);
      .stick {
        height: 100%;
      }
    }
  }


  .tray-container {
    display: flex;
    //flex: 0 0 $sidebar-width;
    overflow-y: hidden;
    margin: 1rem;
    position: relative;
    // make the tray stand out
    border: $border-style;
    @include shadow;
    background-color: white;
    .actions {
      //display: none;
      padding: 0.5rem;
    }

    .tray-image-drop-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .image-tray {
      flex: 1;
    }

    .tray-header {
      padding: 1rem;
      border-bottom: $border-style;
    }

    .tray-footer {
      padding: 0.5rem;
      //border-bottom: $border-style;
    }

    .instructions {
      //background-color: red;
      //border: 4px dashed rgba(0,0,0,0.25);
      pointer-events: none;
      //height: calc(100% - 1rem);
    }


    &:hover {
      overflow-y: auto;
    }

    .image-tray {
      flex: 1;
      padding: 0.25rem;
      // background-color: pink;

      .image-match {
        background-color: $orange;
        font-weight: 600;
        color: white;
      }
    }

    .image-thumbnail {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      border: 2px dashed transparent;
      padding: 0.25rem;

      img {
        width: 8rem;
      }
      &:hover {
        background-color: $hover-bkgd;
        border: 2px dashed black;
      }
      .image-details {
        padding-left: 0.25rem;
      }

      .remove-tray-link {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        display: none;
      }

      &:hover {
        .remove-tray-link {
          display: inherit;
        }
      }
    }
  }

  .location-section {
    //margin-bottom: 0.5rem;
    border: $border-style;
    //padding: 0.75rem;
  }

  #premium-container {
    width: 70rem;
  }

  .property-header {
    @include shadow;
    background-color: white;
    //padding: 0.75rem;
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    //align-items: center;
    .property-label {
      flex: 1;
      padding: 0.75rem;
      border-bottom: $border-style;
      //margin-bottom: 0.5rem;
    }
    .property-actions {
      padding: 0.75rem;
    }
  }

  .list-container {
    //flex: 1;
    overflow: hidden;
    &:hover {
      overflow: auto;
    }
    .property-item {
      display: block;
      padding: 0.5rem;
      border: $border-style;
      margin: 0.25rem;
      cursor: pointer;
      &:hover {
        background-color: $hover-bkgd;
      }
    }
  }

  &.startup {
    //background-color: $green;
    display: flex;
    flex-direction: column;
    //flex: 0 0 $content-width;
    flex: 1;
    margin: 0 auto;
    .main-container {
      margin: 0 !important;

      .startup-instructions {
        @include shadow;
        //padding: 1rem;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 2rem;
        left: 2rem;
        z-index: 9999;
        background-color: white;
        max-height: calc(40% - 1rem);

        input {
          margin-right: 0.5rem;
        }
        width: 25rem;
        //height: 15rem;

      }
    }

    .tray-container {
      //display: none;
      width: $content-width !important;
      &.sidebar-width {
        flex: 0 0 10rem !important;
      }
    }

    .property-header {
      display: none;
    }

    .main-container {
       flex: 1;
       margin: 0 1rem 1rem 1rem;
       display: flex;

       .map-container {
         flex: 1;
         position: relative;
         right: inherit;
         top: inherit;
         width: inherit;
         height: inherit;
       }

       .photo-points-container {
         display: none;
         //margin: 0 auto;
        /* .starter-box { */
        /*   @include shadow; */
        /*   background-color: white; */
        /*   margin: 1rem; */
        /*   border: $border-style; */
        /*   height: 20rem; */
        /*   width: 20rem; */

        /*   .on-hover { */
        /*     display: none; */
        /*     height: 100%; */
        /*     cursor: pointer; */
        /*   } */
        /*   .on-file-hover { */
        /*     background-color: $red; */
        /*     height: 100%; */
        /*   } */
        /*   &:hover { */
        /*     .default { */
        /*       display: none; */
        /*     } */
        /*     .on-hover { */
        /*       display: inherit; */
        /*     } */
        /*   } */
        /* } */
      }
    }
  }

}
</style>
