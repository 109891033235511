<template>
<div
  class="photo-point-location"
  :class="{ minimize }">
  <div
    class="photopoint-location-container">
    <header class="header">
      <InfoLine
        title="Label"
        @click="editLocationField('label', label)">
        {{ prefix }}{{ label }}
      </InfoLine>
      <InfoLine
        title="Description"
        @click="editLocationField('description', description)">
        {{ description }}
      </InfoLine>
      <InfoLine
        title="Latitude"
        @click="editLocationField('lat', lat, 'number')">
        {{ lat }}
      </InfoLine>
      <InfoLine
        title="Longitude"
        @click="editLocationField('lng', lng, 'number')">
        {{ lng }}
      </InfoLine>
    </header>
    <!-- <div class="image-purgatory"> -->
    <!--   Images that have not been mapped to a photo point yet -->
    <!-- </div> -->
    <!-- <div -->
    <!--   v-if="!minimize" -->
    <!--   class="padded"> -->
    <!--   <a -->
    <!--     class="clickable italics" -->
    <!--     @click="addPhotoPoint"> -->
    <!--     Add a new photo point -->
    <!--   </a> -->
    <!-- </div> -->

    <div
      v-if="!minimize"
      class="photopoint-dropboxes row">
      <div
        class="photo-point"
        v-for="(p, i) in points"
        :key="p.uid"
        :title="p.description">
        <Image
          :url="p.image && p.image.url"
          :class="{ clickable: p.image && p.image.url }"
          :index="p.uid"
          @click="showImageViewer(i)"
          @dropped="addPhotoPointImage($event, p.uid)">
          <!-- When things come back from indexed storage we have a tag but no image -->
          <IconLink
            v-if="!(p.image && p.image.url)"
            class="error"
            title="Remove this photo point direction"
            @clicked="removePhotoPoint(p.uid)">
            close
          </IconLink>
          <IconLink
            v-if="p.image && p.image.url"
            @clicked="downloadImage(p.image.url, `${prefix}${label}${p.label}_${p.image.datetime}.jpg`)">
            cloud_download
          </IconLink>
          <IconLink
            :success="true"
            v-if="p.image && p.image.notes">
            check
          </IconLink>
        </Image>
        <InfoLine
          title="lbl"
          @click="editPhotoPointField(p.uid, 'label', p.label)">
          {{ p.label }}
        </InfoLine>
        <InfoLine
          title="dir"
          @click="editPhotoPointField(p.uid, 'azimuth', p.azimuth, 'number')">
          {{ p.azimuth || p.azimuth === 0 ? Math.round(p.azimuth) : '..'}}
        </InfoLine>
      </div>
    </div>
  </div>

  <div class="footer row padded">
    <!-- <a -->
    <!--   class="clickable italics padded" -->
    <!--   @click="$emit('copy', { photopoints: [ ...points ] })"> -->
    <!--   Copy photo point location -->
    <!-- </a> -->
    <div class="fill row center">
      <IconLink
        class="success"
        title="Add another photo point direction to this location"
        @clicked="addPhotoPoint">
        add
      </IconLink>
      <span class="padded"/>
      <span>
        {{ points.length }} photo points
      </span>
    </div>
    <IconLink
      v-if="uid"
      class="error"
      title="Remove this location and all photo point directions"
      @clicked="$emit('remove', uid)">
      remove
    </IconLink>
    <IconLink
      v-if="uid"
      class="activated"
      title="Download this photo point location"
      @clicked="downloadPhotoPointLocation">
      cloud_download
    </IconLink>
  </div>

  <div
    v-if="viewingImage"
    class="modal-container">
    <ImageViewer
      :label="selectedPhotoPointLabel"
      :notes="selectedPhotoPointNotes"
      v-bind="selectedPhotoPointImage"
      @close="viewingImage = false"
      @next="showNextImage"
      @previous="showPrevImage"
      @updated="updateField"/>
  </div>


</div>
</template>
<script>
import Image from '@/components/Image';
import ImageViewer from '@/components/ImageViewer';
import InfoLine from '@acorns-ui/Text/InfoLine';
import IconLink from '@acorns-ui/Icons/IconLink';
import { mapState } from 'vuex';
import { get } from '@/utils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: 'PhotoPointLocation',
  props: {
    uid: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    lat: {
      type: Number,
      //  default: 45.1254,
    },
    lng: {
      type: Number,
      // default: -123.5698,
    },
    points: {
      type: Array,
      default: () => ([]),
    },
    minimize: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewingImage: false,
      selectedPhotoPointId: null,
      selectedPhotoPointIndex: null,
    };
  },
  components: {
    IconLink,
    Image,
    InfoLine,
    ImageViewer,
  },
  created() {},
  watch: {},
  computed: {
    ...mapState([
      'prefix',
    ]),
    selectedPhotoPointImage() {
      return get([this.selectedPhotoPointIndex, 'image'], this.points);
    },
    selectedPhotoPointLabel() {
      const ppl =  get([this.selectedPhotoPointIndex, 'label'], this.points);
      return `Photo Points > ${this.label} > ${ppl}`;
    },
    selectedPhotoPointNotes() {
      return get([this.selectedPhotoPointIndex, 'notes'], this.points);
    },
  },
  methods: {
    editLocationField(key, value, type = 'text') {
      const id = this.uid;
      const source = 'photopoints/updateCollectionRecordField';
      this.$emit('edit', { id, key, value, type, source })
    },
    editPhotoPointField(id, key, value, type = 'text') {
      const source = 'photopoints/updatePhotoPointRecord';
      //const source = 'photopoints/UPDATE_PHOTOPOINT_RECORD';
      this.$emit('edit', { id, key, value, type, source })
    },
    addPhotoPoint() {
      // add and show the
      const id = this.uid;
      const data = { };
      this.$store.dispatch('photopoints/addPhotoPoint', { id, data })
    },
    removePhotoPoint(id) {
      this.$store.dispatch('photopoints/removePhotoPoint', id)
    },
    addPhotoPointImage(data, id) {
      // could either be an image or a reference to an image
      if(data.source && data.source == 'tray') {
        // transfer the image
        // get the image from the tray
        let idx = data.index*1;
        this.$store.dispatch('getTrayImage', { idx })
          .then( image => {
            this.$store.dispatch('photopoints/addPhotoPointImage', { id, image })
          })
      } else if(data.source && data.source == 'additionalPhotos') {
        let idx = data.index*1;
        this.$store.dispatch('getAdditionalPhotoImage', { idx })
          .then( image => {
            this.$store.dispatch('photopoints/addPhotoPointImage', { id, image })
          })
      } else if(data.source && data.source == 'photopoints') {
        let idx = data.index*1;
        this.$store.dispatch('photopoints/getPhotoPointImage', { id: idx })
          .then( image => {
            this.$store.dispatch('photopoints/addPhotoPointImage', { id, image })
          })
      } else {
        // add the image
        this.$store.dispatch('photopoints/addPhotoPointImage', { id, image: data })
      }
    },
    updateField({ key, value }) {
      // update photo points store
      const uid = this.selectedPhotoPointId;
      this.$store.dispatch('photopoints/updatePhotoPointRecord', { uid, [key]: value });
    },
    showImageViewer(i) {
      const p = this.points[i]
      if(p.image && p.image.url) {
        this.selectedPhotoPointIndex = i;
        this.selectedPhotoPointId = p.uid;
        this.viewingImage = true;
      }
    },
    showNextImage() {
      const n = this.points.length;
      const i = this.selectedPhotoPointIndex === n-1
            ? 0
            : this.selectedPhotoPointIndex + 1;
      const p = this.points[i];
      this.selectedPhotoPointIndex = i;
      this.selectedPhotoPointId = p.uid;
    },
    showPrevImage() {
      const n = this.points.length;
      const i = this.selectedPhotoPointIndex === 0
            ? n - 1
            : this.selectedPhotoPointIndex - 1;
      const p = this.points[i];
      this.selectedPhotoPointId = p.uid;
      this.selectedPhotoPointIndex = i;
    },
    downloadPhotoPointLocation() {
      var zip = new JSZip()
      var prefix = this.prefix.replace('[: ]*', '_');
      var location = this.label.replace(':', '_');
      var csv = 'image, original_name, datetime, lat, lng, alt\n\r'
      // this call will create photos/README
      this.points
        .filter(d => d.image)
        .map(d => {
          var label = d.label;
          var date = d.image.datetime;
          var name = `${prefix}${location}${label}_${date}.jpg`;
          csv += `"${name}","${d.image.name}","${d.image.datetime}",${d.image.lat},${d.image.lng},${d.image.direction},${d.image.alt}\n\r`;
          zip.file(name, d.image.file);
        })

      zip.file("manifest.csv", csv);
      zip.generateAsync({type:"blob"})
        .then(function (blob) {
          saveAs(blob, `${prefix}${location}.zip`);
        });
    },
    downloadImage(url, filename) {
      var element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/modals';

.photo-point-location  {
  width: 100%;
  background-color: white;

  &.minimize {
    width: 3*$inch;
  }

  .header {
    background-color: white;
    z-index: 1;
    position: sticky;
    top: 0;
  }

  .photopoint-dropboxes {
    flex-wrap: wrap;
    align-items: flex-start;

    .photo-point {
      border-top: $border-style;
      border-left: $border-style;
      border-right: $border-style;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      width: 9.5rem;
      //background-color: #046380;
      //background-color: $green;
      background-color: rgba(0,0,0,0.1);
      //color: $white;
    }

  }

  .modal-container {
    @include modal;
  }
}
</style>
