<template>
<div class="strip-payment-form">
  MAKE A PAYMENT

  {{ sessionId }}

  {{ apiKey }}
</div>
</template>
<script>

export default {
  name: 'StripPaymentForm',
  props: {
    sessionId: {
      type: String,
    },
    apiKey: {
      type: String,
    }
  },
  data() {
    return {};
  },
  components: {},
  created() {
    this.redirectToCheckout();
  },
  watch: {},
  computed: {},
  methods: {
    redirectToCheckout() {
      var stripe = Stripe(this.apiKey);  // eslint-disable-line
      stripe.redirectToCheckout({ sessionId: this.sessionId });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.strip-payment-form  {

}
</style>
