<template>
<div class="pricing-card">
  <div class="body">

    <div class="header text-center">
      <div class="graphic">

      </div>
      <div class="category">
        {{ category }}
      </div>
      <div class="price-per">
        <span
          class="price">
          ${{ price }}
        </span>
        <span
          class="per">
          /{{ period }}
        </span>
      </div>
    </div>

    <div class="features">
      <div
        v-for="feature in features"
        :key="feature"
        class="row center">
        <Icon
          class="small success">
          check_circle
        </Icon>
        <div class="padded">
          {{ feature }}
        </div>
      </div>
    </div>

  </div>

  <div class="footer">
    <Button
      type="save"
      @click="$emit('selected')">
      {{ actionLabel }}
    </Button>
  </div>

</div>
</template>
<script>

import Icon from '@acorns-ui/Icons/FontIcon';
  import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'PricingCard',
  props: {
    category: {
      type: String,
      default: 'free',
    },
    price: {
      type: Number,
      default: 0,
    },
    period: {
      type: String,
      default: 'month',
    },
    features: {
      type: Array,
      default: () => ([])
    },
    actionLabel: {
      type: String,
      default: 'free',
    },
  },
  data() {
    return {};
  },
  components: {
    Icon,
    Button,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.pricing-card {
  border: $border-style;
  min-height: 20rem;
  //width: 15rem;

  display: flex;
  flex-direction: column;

  .text-center {
    text-align: center;
  }


  .body {
    flex: 1;

    .features {
      margin: 1rem;
    }
  }

  .header {
    margin-top: 1rem;
  }

  .category {
    font-size: 1.5rem;
    text-transform: capitalize;
  }

  .price-per {
    font-weight: 600;
    .price {
      font-size: 3.5rem;
    }
    .per {
      font-size: 0.85rem;
    }
  }

  .footer {
    padding: 1rem;
    margin: 0 auto;
    button {
      min-width: 10rem;
    }
  }
}
</style>
